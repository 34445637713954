

















































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { dieta_visualizacion } from "@/shared/dtos/visualizacion_dieta/dieta_visualizacion";
import ViewDataPlato from "./view_data_plato.vue";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
@Component({
  components: {
    ViewDataAlimento: () =>
      import("@/views/entrevista/dieta-visualizacion/ViewDataAlimento.vue"),
    ViewDataPlato: () =>
      import("@/views/entrevista/dieta-visualizacion/ViewDataPlato.vue"),
    ViewDataSuplemento: () =>
      import("@/views/entrevista/dieta-visualizacion/ViewDataSuplemento.vue")
  }
})
export default class dialog_datos_plato extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: Boolean;
  @PropSync("item", { type: Object }) syncit!: dieta_visualizacion;
  public created() {}
  public get color_item() {
    switch (this.syncit.id_tipo_dato) {
      case 1:
        return "title_plato";
        break;
      case 2:
        return "title_suplemento";
        break;
      case 3:
        return "title_alimento";
        break;
    }
  }

  public save(dato: dieta_visualizacion) {
    switch (this.syncit.id_tipo_dato) {
      case 1:
        this.syncit.id_estructura = dato.id_estructura;
        this.syncit.cantidad = 0;
        break;
      case 2:
        return "title_suplemento";
        break;
      case 3:
        this.syncit.cantidad = dato.cantidad;
        break;
    }
    dieta_visualizacionModule
      .modificarplato_dieta(dato)
      .then((x: dieta_visualizacion) => {
        this.syncit.cantidad = x.cantidad;
        dieta_visualizacionModule.getdieta_estadisticas({
          id_dieta: this.syncit.id_dieta,
          id_paciente: 2
        });
      });
    this.syncedDialog = false;
  }
  public getDuplicate() {
    return new dieta_visualizacion(this.syncit);
  }
}
